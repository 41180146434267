import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-3 2xl:my-5 py-3 px-10 2xl:py-1 2xl:px-20" }
const _hoisted_2 = { className: "grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 w-full 2xl:py-5 py-3 mb-5" }
const _hoisted_3 = { class: "mt-1 md:mt-0 md:col-start-2 xl:col-start-3 2xl:col-start-4 relative" }
const _hoisted_4 = { class: "inputfield form-group" }
const _hoisted_5 = { class: "absolute top-1/2 left-1 transform -translate-y-1/2" }
const _hoisted_6 = { class: "mt-5 flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSvgIcon = _resolveComponent("BaseSvgIcon")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("h1", {
        className: "md:col-start-1 md:col-span-2 text-2xl 2xl:text-4xl font-bold text-white",
        "data-aos": "fade-right"
      }, " Customer Overview ", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("i", _hoisted_5, [
            _createVNode(_component_BaseSvgIcon, {
              class: "inline-block text-white w-5 h-5",
              name: "search"
            })
          ]),
          _createElementVNode("input", {
            type: "search",
            placeholder: "Search",
            class: "pl-10",
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleSearchingAPI && _ctx.handleSearchingAPI(...args)))
          }, null, 32)
        ])
      ])
    ]),
    _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"flex flex-wrap mb-5\"><div class=\"w-40 h-40 rounded-lg bg-gray-400 bg-opacity-20 flex flex-col justify-between items-start p-5 mr-5 mb-5\" data-aos=\"zoom-out-up\" data-aos-duration=\"600\"><h1 class=\"2xl:text-lg\">Total customer</h1><strong class=\"text-5xl bottom-0\">10</strong></div><div class=\"w-40 h-40 rounded-lg bg-gray-400 bg-opacity-20 flex flex-col justify-between items-start p-5 mr-5 mb-5\" data-aos=\"zoom-out-up\" data-aos-duration=\"750\"><h1 class=\"2xl:text-lg\">Total client admin</h1><strong class=\"text-5xl bottom-0\">15</strong></div><div class=\"w-40 h-40 rounded-lg bg-gray-400 bg-opacity-20 flex flex-col justify-between items-start p-5 mr-5 mb-5\" data-aos=\"zoom-out-up\" data-aos-duration=\"900\"><h1 class=\"2xl:text-lg\">Electricity Usage (MW)</h1><strong class=\"text-5xl bottom-0\">500</strong></div><div class=\"w-40 h-40 rounded-lg bg-gray-400 bg-opacity-20 flex flex-col justify-between items-start p-5 mr-5 mb-5\" data-aos=\"zoom-out-up\" data-aos-duration=\"1050\"><h1 class=\"2xl:text-lg\">Energy Renewable (%)</h1><strong class=\"text-5xl bottom-0\">30</strong></div><div class=\"w-40 h-40 rounded-lg bg-gray-400 bg-opacity-20 flex flex-col justify-between items-start p-5 mr-5 mb-5\" data-aos=\"zoom-out-up\" data-aos-duration=\"1200\"><h1 class=\"2xl:text-lg\">Total RECs transactions</h1><strong class=\"text-5xl bottom-0\">353</strong></div></div>", 1)),
    _createVNode(_component_el_table, {
      class: "w-full rounded-lg bg-gray-400 bg-opacity-20 shadow-sm p-5",
      data: _ctx.tableData,
      stripe: "",
      "header-row-class-name": "text-black"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "CustomerName",
          label: "Customer Name"
        }),
        _createVNode(_component_el_table_column, {
          prop: "Country",
          label: "Location"
        }),
        _createVNode(_component_el_table_column, {
          prop: "Contract",
          label: "Contract"
        }),
        _createVNode(_component_el_table_column, {
          prop: "RECsSold",
          label: "RECs sold"
        }),
        _createVNode(_component_el_table_column, {
          prop: "RECsPurchased",
          label: "RECs purchased"
        })
      ]),
      _: 1
    }, 8, ["data"]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_el_pagination, {
        currentPage: _ctx.pagination.CurrentPage,
        "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pagination.CurrentPage) = $event)),
        "page-sizes": [100, 200, 300, 400],
        "page-size": _ctx.pagination.Limit,
        layout: "sizes, prev, pager, next",
        total: _ctx.pagination.TotalRow,
        onSizeChange: _ctx.handleSizeChange,
        onCurrentChange: _ctx.handleCurrentChange
      }, null, 8, ["currentPage", "page-size", "total", "onSizeChange", "onCurrentChange"])
    ])
  ]))
}